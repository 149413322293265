export interface option_descriptor {
    name: string;
    default: boolean;
    description: string;
}

export interface option_group {
    "group-name": string;
    options: option_descriptor[];
}

export const options: option_group[] = [
    {
        "group-name": "General",
        options: [
            {
                name: "friendly-name",
                default: true,
                description:
                    'Use user-friendly type names such as "table-cell", over standard element names like "CT_Tc"'
            },
            {
                name: "merge-consecutive-text",
                default: true,
                description:
                    "Sentences and even single words can be represented by multiple text elements. If `true`, concatenate consecutive text elements into a single text element."
            }
        ]
    },

    {
        "group-name": "Ignoring Invisible things",
        options: [
            {
                name: "ignore-empty-paragraphs",
                default: true,
                description:
                    "Empty paragraphs are often used for styling purpose and rarely have significance in the meaning of the document."
            },

            {
                name: "ignore-empty-text",
                default: true,
                description:
                    "Empty text runs can make an otherwise empty paragraph appear to contain data."
            },

            {
                name: "remove-leading-white-space",
                default: true,
                description:
                    "Leading white-space at the start of a paragraph is ocassionaly used for styling purposes and rarely has significance in the interpretation of a document."
            },

            {
                name: "remove-trailing-white-space",
                default: true,
                description:
                    "Trailing white-space at the end of a paragraph rarely has significance in the interpretation of a document."
            },

            {
                name: "flatten-inner-spaces",
                default: false,
                description:
                    "Collapse multiple space characters between words to a single space."
            },

            {
                name: "ignore-joiners",
                default: false,
                description:
                    "Zero width joiner and non-joiner characters are special characters used to create ligatures in displayed text and don't typically convey meaning (at least in alphabet based languages)."
            }
        ]
    },

    {
        "group-name": "Special symbols",
        options: [
            {
                name: "dumb-quotes",
                default: true,
                description: "Replace smart quotes with dumb quotes."
            },

            {
                name: "dumb-hyphens",
                default: true,
                description:
                    "Replace en-dash, em-dash, figure-dash, horizontal bar, and non-breaking hyphens with ordinary hyphens."
            },

            {
                name: "dumb-spaces",
                default: true,
                description:
                    "Replace zero width spaces, hair spaces, thin spaces, punctuation spaces, figure spaces, six per em spaces, four per em spaces, three per em spaces, em spaces, en spaces, em quad spaces, and en quad spaces with ordinary spaces."
            },

            {
                name: "special-characters-as-text",
                default: true,
                description: "Coerce special characters into text equivalents"
            },

            {
                name: "symbol-as-text",
                default: true,
                description:
                    "Special symbols often cary meaning other than the underlying unicode character, especially when the font is a special font such as `Wingdings`. If `true` these are included as ordinary text and their font information is omitted."
            },

            {
                name: "empty-as-text",
                default: false,
                description:
                    'There are a variety of "Empty" tags such as the `<"w:yearLong">` tag which cause the current year to be inserted into the document text. If `true`, include these as text formatted as `"[yearLong]"`.'
            },

            {
                name: "ignore-left-to-right-mark",
                default: false,
                description:
                    "Ignore the left-to-right mark, which is not writeable by pythons csv writer."
            },

            {
                name: "ignore-right-to-left-mark",
                default: false,
                description:
                    "Ignore the right-to-left mark which is not writeable by pythons csv writer."
            }
        ]
    },

    {
        "group-name": "Paragraph style",
        options: [
            {
                name: "include-paragraph-indent",
                default: true,
                description:
                    "Include the indentation markup on paragraph (`CT_P`) elements. Indentation is measured in twips"
            },

            {
                name: "include-paragraph-numbering",
                default: true,
                description:
                    "Include the numbering styles, which are included in the `CT_P.pPr.numPr` element. The `ilvl` attribute indicates the level of nesting (zero based index) and the `numId` attribute refers to a specific numbering style included in the document's internal styles sheet. "
            }
        ]
    },
    {
        "group-name": "Form Elements",
        options: [
            {
                name: "simplify-dropdown",
                default: true,
                description:
                    "Include just the selected and default values, the available options, and the name and label attributes in the form element."
            },

            {
                name: "simplify-textinput",
                default: true,
                description:
                    "Include just the current and default values, and the name and label attributes in the form element."
            },

            {
                name: "greedy-text-input",
                default: true,
                description:
                    "Continue consuming run elements when the text-input has not ended at the end of a paragraph, and the next block level element is also a paragraph. This typically occurs when the user preses the return key while editing a text input field."
            },

            {
                name: "simplify-checkbox",
                default: true,
                description:
                    "Include just the current and default values, and the name and label attributes in the form element."
            },

            {
                name: "use-checkbox-default",
                default: true,
                description:
                    "If the checkbox has no `value` attribute (typically because the user has not interacted with it), report the default value as the checkbox value."
            },

            {
                name: "checkbox-as-text",
                default: false,
                description:
                    'Coerce the value of the checkbox to text, represented as either `"[CheckBox:true]"` or `"[CheckBox:false]"`'
            },

            {
                name: "dropdown-as-text",
                default: false,
                description:
                    'Coerce the value of the checkbox to text, represented as `"[DropDown:<selected value>]"`'
            },

            {
                name: "trim-dropdown-options",
                default: true,
                description:
                    "Remove white-space on the left and right of drop down option items."
            },

            {
                name: "flatten-generic-field",
                default: true,
                description:
                    "`generic-fields` are `CT_FldChar` runs which are not marked as a drop-down, text-input, or checkbox. These may include special instructions which apply special formatting to a text run (e.g. a hyper link). If `true`, the contents of generic-fields are included in the normal flow of text"
            }
        ]
    },

    {
        "group-name": "Special content",
        options: [
            {
                name: "flatten-hyperlink",
                default: true,
                description:
                    "Flatten hyperlinks, including their contents in the flow of normal text."
            },

            {
                name: "flatten-smartTag",
                default: true,
                description:
                    "Flatten smartTag elements, including their contents in the flow of normal text."
            },

            {
                name: "flatten-customXml",
                default: true,
                description:
                    "Flatten customXml elements, including their contents in the flow of normal text."
            },

            {
                name: "flatten-simpleField",
                default: true,
                description:
                    "Flatten simpleField elements, including their contents in the flow of normal text."
            }
        ]
    }
];

interface option_values {
    [option: string]: boolean;
}
export const default_options: option_values = options
    .map(g => g.options)
    .flat()
    .reduce((a: option_values, v: option_descriptor) => {
        a[v.name] = v.default;
        return a;
    }, {});

interface _option_descriptions {
    [option: string]: string;
}
export const option_descriptions: _option_descriptions = options
    .map(g => g.options)
    .flat()
    .reduce((a: _option_descriptions, v: option_descriptor) => {
        a[v.name] = v.description;
        return a;
    }, {});
