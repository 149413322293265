import React, { useEffect, } from 'react';
import { connect } from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import { createStyles, fade, Theme, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import ClearIcon from '@material-ui/icons/Clear';
import { set_jmespath, deactivate_jsmespath, jmespath_error, activate_jmespath } from "./action-creaters"
import { storeState } from "./store";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        text: {
            padding: theme.spacing(2, 2, 0),
        },
        paper: {
            paddingBottom: 50,
        },
        list: {
            marginBottom: theme.spacing(2),
        },
        subheader: {
            backgroundColor: theme.palette.background.paper,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            top: 'auto',
            bottom: 0,
        },
        grow: {
            flexGrow: 1,
        },
        fabButton: {
            position: 'absolute',
            zIndex: 1,
            top: -30,
            left: 0,
            right: 0,
            margin: '0 auto',
        },



        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '100%',
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 1),
            transition: theme.transitions.create('width'),
            width: '100%',
        },


    }),
);

export const BottomAppBar: React.FC<toolbarProps & toolbarSetters> = props => {
    const classes = useStyles();

    const callback = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            event.stopPropagation();
            event.preventDefault();
            if (props.jmesquery_result || (props.jmesquery_result === null)) {
                props.deactivate_jsmespath()
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', callback);
        return () => { window.removeEventListener('keydown', callback) };
    }, [!(props.jmesquery_result || (props.jmesquery_result === null))])

    return (
        <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar>
                <div className={classes.search}>
                    <InputBase
                        placeholder="Apply jmespath…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(ev) => { props.set_jmespath(ev.target.value) }}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") { props.activate_jmespath(); return }
                        }}
                    />
                </div>
                <IconButton
                    disabled={!props.jmesquery_is_valid}
                    color="inherit"
                    size="small"
                    onClick={(ev) => { props.activate_jmespath() }}>
                    <DoneIcon />
                </IconButton>
                <IconButton
                    disabled={!props.jmesquery_result && (props.jmesquery_result !== null)}
                    color="inherit"
                    size="small"
                    onClick={props.deactivate_jsmespath}>
                    <ClearIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}


interface toolbarProps {
    jmesquery: string;
    jmesquery_is_valid: boolean;
    jmesquery_result?: any;
    jmesquery_error?: any;
}

interface toolbarSetters {
    set_jmespath: { (x: string): void };
    deactivate_jsmespath: { (): void };
    activate_jmespath: { (): void };
    jmespath_error: { (x: string): void };
}

function mapStateToProps(state: storeState): toolbarProps {
    const { jmesquery, jmesquery_is_valid, jmesquery_result, jmesquery_error, } = state;
    return { jmesquery, jmesquery_is_valid, jmesquery_result, jmesquery_error, }
}

function mapDispatchToProps(dispatch: { (x: any): void }): toolbarSetters {
    return {
        set_jmespath: (x: string) => { dispatch(set_jmespath(x)) },
        deactivate_jsmespath: () => dispatch(deactivate_jsmespath()),
        activate_jmespath: () => dispatch(activate_jmespath()),
        jmespath_error: (x: string) => dispatch(jmespath_error(x))
    }
}

export const Footer = connect(mapStateToProps, mapDispatchToProps)(BottomAppBar);
export default Footer


