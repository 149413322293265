import axios from "axios";
import JmesPath from "jmespath";
import { ThunkResult, action, storeState, set_contents_action } from "./store";

interface actionCreator {
    (...args: any[]): action;
}

export const set_jmespath: actionCreator = (x: string) => {
    var is_valid: boolean;
    try {
        (JmesPath as any).compile(x);
        is_valid = true;
    } catch {
        is_valid = false;
    }
    return {
        type: "SET_JMESPATH",
        payload: x,
        is_valid: is_valid
    };
};

export const deactivate_jsmespath: actionCreator = () => ({
    type: "DEACTIVATE_JSMESPATH"
});

export const jmespath_error: actionCreator = (x: string) => ({
    type: "JMESPATH_ERROR",
    message: x
});

export function activate_jmespath(): ThunkResult<void> {
    return (dispatch: { (x: action): void }, getState: { (): storeState }) => {
        const { file_contents, jmesquery } = getState();
        try {
            var out = JmesPath.search(file_contents, jmesquery);
        } catch {
            dispatch({
                type: "JMESPATH_ERROR",
                message: "something went wrong"
            });
        }
        dispatch({
            type: "ACTIVATE_JMESPATH",
            payload: out
        });
    };
}

export function create_path_action(
    path: string,
    file: File
): ThunkResult<void> {
    return (dispatch: { (x: action): void }, getState: { (): storeState }) => {
        if (!path.endsWith(".docx")) {
            dispatch({
                type: "INVALID_FILE",
                path: path,
                message: "Not a docx file"
            });
            return;
        }
        dispatch({
            type: "SET_PATH",
            path: path,
            file: file
        });
        var formdata = new FormData();
        const { options } = getState();
        formdata.append("options", JSON.stringify(options));
        //console.log("file type", typeof file, file);
        if (!file.size) {
            dispatch({
                type: "INVALID_FILE",
                path: path,
                message: "Empty file (Zero bytes received)"
            });
            return;
        }
        formdata.append("file", file, "contents.docx");
        axios({
            method: "post",
            url: "/service",
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(response => {
                //console.log("handling resopnse...:", typeof response.data);
                if (response.data.success) {
                    //console.log("SET_CONTENTS");
                    dispatch({
                        type: "SET_CONTENTS",
                        path: path,
                        payload: response.data.body
                    });
                } else {
                    //console.log("response.success:", response.data.success);
                    //console.log("response.data:", response.data);
                    dispatch({
                        type: "INVALID_FILE",
                        path: path,
                        message: response.data.message
                    });
                }
            })
            .catch(response => {
                //console.log("failed response", response);
                dispatch({
                    type: "INVALID_FILE",
                    path: path,
                    message: "something went wrong with the request"
                });
            });
    };
}

export function create_contents_action(
    path: string,
    payload: any
): set_contents_action {
    return {
        type: "SET_CONTENTS",
        path: path,
        payload: payload
    };
}

export const drop_error: actionCreator = (message: string) => ({
    type: "INVALID_FILE",
    path: "[Drop Error]",
    message: message
});

export function create_toggle_option_action(option: string): ThunkResult<void> {
    return (dispatch: { (x: action): void }, getState: { (): storeState }) => {
        dispatch({
            type: "TOGGLE_OPTION",
            name: option
        });

        const { file, options, file_path } = getState();
        if (!file_path || !file) {
            //console.log("adfadfsafdafsafdasfdafadfafa", file_path, file);
            return;
        }

        var formdata = new FormData();
        formdata.append("options", JSON.stringify(options));
        //console.log("file type", typeof file, file);
        if (!file.size) {
            dispatch({
                type: "INVALID_FILE",
                path: file_path,
                message: "Empty file (Zero bytes received)"
            });
            return;
        }
        formdata.append("file", file, "contents.docx");
        axios({
            method: "post",
            url: "/service",
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(response => {
                //console.log("response.data:", response.data);
                if (response.data.success) {
                    dispatch({
                        type: "SET_CONTENTS",
                        path: file_path,
                        payload: response.data.body
                    });
                } else {
                    dispatch({
                        type: "INVALID_FILE",
                        path: file_path,
                        message: response.data.message
                    });
                }
            })
            .catch(response => {
                //console.log("failed response", response);
                dispatch({
                    type: "INVALID_FILE",
                    path: file_path,
                    message: "something went wrong with the request"
                });
            });
    };
}
