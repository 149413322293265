import React from 'react';
import { connect } from "react-redux";
import { withStyles, Theme } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { storeState } from "./store"
import { options as option_spec, option_descriptions, option_group } from "./options"
import { create_toggle_option_action } from "./action-creaters"

// --------------------------------------------------
// ITEM
// --------------------------------------------------

const BigTooltip = withStyles((theme: Theme) => ({
  tooltip: { fontSize: 15, },
}))(Tooltip);

interface itemProps {
  name: string,
  checked: boolean,
  toggle_setting: { (x: string): void }
};

const _Option: React.FC<itemProps> = props => (
  <BigTooltip title={option_descriptions[props.name]} enterDelay={2500} placement="right" key={props.name}>
    <ListItem button onClick={() => props.toggle_setting(props.name)} dense={true}>
      <Checkbox
        color="primary"
        checked={props.checked}
        value="key"
        size="small" />
      <ListItemText primary={props.name} />
    </ListItem>
  </BigTooltip>
)

function mapStateToProps(state: storeState, ownProps: { name: string }) {
  return { checked: state.options[ownProps.name] }
}
function mapDispatchToProps(dispatch: { (x: any): void }) {
  return {
    toggle_setting: (x: string) => {
      dispatch(create_toggle_option_action(x))
    }
  }
}
const Option = connect(mapStateToProps, mapDispatchToProps)(_Option);

// --------------------------------------------------
// OPTION GROUP
// --------------------------------------------------

const _OptionGroup: React.FC<option_group> = props => {

  const [open, setOpen] = React.useState(false);
  const handleClick = () => { setOpen(!open); };

  return (
    <>
      <ListItem button key={props["group-name"] + "--title"} onClick={handleClick} dense={true} >
        <ListItemText primary={props["group-name"]} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit key={props["group-name"]}>
        <List>
          {
            props.options.map(item => (<Option name={item.name} key={item.name} />))
          }
        </List>
      </Collapse>
    </>
  )
};

const OptionGroup = connect(null, mapDispatchToProps)(_OptionGroup);

// --------------------------------------------------
// OPTION LIST
// --------------------------------------------------

// interface ol_props { open_state: {[x:string]:boolean} }

const OptionList: React.FC = props => (
  <List>
    {
      option_spec.map((ol: option_group) => <OptionGroup {...ol} key={ol["group-name"]} />)
    }
  </List>
)
export default OptionList