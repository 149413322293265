import React from 'react';
import { connect } from "react-redux";
import { storeState } from "./store"
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ReactJson from 'react-json-view'
import CircularProgress from '@material-ui/core/CircularProgress'
import SaveIcon from '@material-ui/icons/Save';


const useStyles = makeStyles(theme => ({
    error: {
        paddingBottom: "1rem",
    },
    paper: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        margin: "3em"
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: ("relative" as "relative"), // obsurd code to satisfy tsc
    },
    fabProgress: {
        position: ("absolute" as "absolute"), // obsurd code to satisfy tsc
        top: -6,
        left: -6,
        zIndex: 1,
    },
}));

const Main: React.FC<mainProps> = (props) => {

    const classes = useStyles();

    if (props.error_message) {
        return (<Typography color="error">{props.error_message}</Typography>)
    }
    if (props.contents) {
        return (
            <>
                {props.jmesquery_result === null &&
                    <div className={classes.error}>
                        <Typography color="error">[Jmespath returned null]</Typography>
                    </div>
                }
                <ReactJson
                    src={!props.jmesquery_result ?
                        props.contents :
                        props.jmesquery_result}
                    displayObjectSize={false}
                    displayDataTypes={false}
                    enableClipboard={false}
                />
            </>
        )
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Fab
                        aria-label="save"
                        color="primary"
                    >
                        <SaveIcon />
                    </Fab>
                    {props.loading && <CircularProgress size={68} className={classes.fabProgress} />}

                </div>
            </div>
            <div className={classes.paper}>
                <Paper>
                    <div className={classes.content}>
                        <h2>Why</h2>
                        <Typography>DOCX files are complex, and their complexity makes scraping documents for their content difficult. If you have a corpus of documents that you need to get information out of, you probably are less intersted in understanding the guts of <a href="https://en.wikipedia.org/wiki/Office_Open_XML" style={{ textDecoration: 'none' }}>the Docx format</a> and more interested in simply getting data out of your documents.</Typography>

                        <h2>How</h2>
                        <Typography>Converting .docx files to greatly simplified and <em>human readable</em> json docuemnts allows docx files to be processed with battle tested tools such as <a href="https://json-schema.org/" style={{ textDecoration: 'none' }}>JSON Schema</a> and <a href="http://jmespath.org/" style={{ textDecoration: 'none' }}>JMES path</a>. The goal of the underlying python package is therefor to simplify documents to their meaningful parts, and no simpler -- allowing you to specify which parts are meaninful and which parts aren't. To try it out, <strong style={{color:"#ebb71c"}}>drop a document onto the page</strong> and play with options in the menu. Then install the Python pacakage to automate your data flow and away you go.</Typography>

                        <h2>About this Site</h2>
                        <Typography>This site sends docuemnts to a backend server and the simplified JSON is returned. Nothing is ever stored on the server, but if you're docuemnts are precious and you don't trust the internets, then install the Python package and explore your docuemnts on your own machine in just 4 lines of code.</Typography>
                    </div>
                </Paper>
            </div>
        </>
    )

}

interface mainProps {
    contents?: any;
    jmesquery_result?: any;
    error_message?: string;
    loading: boolean;
}

function mapStateToProps(state: storeState): mainProps {
    return {
        contents: state.file_contents,
        error_message: state.error_message,
        loading: state.loading,
        jmesquery_result: state.jmesquery_result,
    };
}

export default connect(mapStateToProps)(Main);
