/// <reference path="./github-tag.d.ts" />

import React, { FC } from 'react';
import { connect } from "react-redux";
import { storeState } from "./store"
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import OptionList from "./option-list"
import DropZone from "./dropzone"
import Footer from "./footer"
import GitHubForkRibbon from "react-github-fork-ribbon"
import Main from "./main"
import Download from "./download"
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  red: {
    color: theme.palette.secondary.dark
  },
  handle: {
    paddingRight: '0.6rem'
  },
}));

interface appProps {
  file_path?: string;
  has_contents: boolean;
}





const App: React.FC<appProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => { setOpen(!open) };
  const classes = useStyles();

  const MyDrawer = (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <OptionList />
      <div className={classes.toolbar} />
    </Drawer>)

  return (

    <DropZone>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <GitHubForkRibbon href="//github.com/microsoft/simplify-docx"
            target="_blank"
            position="right"
            color="black">
            Fork me on GitHub
          </GitHubForkRibbon>
          <Toolbar>
            <div className={classes.handle}>
              {open ?
                <MenuOpenRoundedIcon
                  onClick={toggleOpen} /> :
                <MenuRoundedIcon
                  onClick={toggleOpen} />}
            </div>
            <div className={classes.handle}>
              <Download />
            </div>
            <Typography variant="h6" noWrap>
              {props.file_path ?
                [props.file_path.endsWith(".docx") ? null : <span className={classes.red}>[not a .docx file] </span>, props.file_path] :
                "Drop a .docx file into the page to inspect"}
            </Typography>
          </Toolbar>
        </AppBar>
        {open && MyDrawer}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Main />
          <div className={classes.toolbar} />
        </main>
        {props.has_contents ? <Footer /> : null}
      </div>
    </DropZone>
  )
}

function mapStateToProps(state: storeState): appProps {
  return {
    file_path: state.file_path,
    has_contents: !!state.file_contents,
  };
}

export default connect(mapStateToProps)(App);