import React from 'react';
import { storeState } from "./store"
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import { connect } from "react-redux";

interface downloadProps {
    contents?: any;
    filename?: string;
}

function download(filename: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

const Download: React.FC<downloadProps> = (props) => {
    return (
        <IconButton
            disabled={!props.contents}
            color="inherit"
            size="small"
            onClick={() => {
                download(
                    props.filename!.substring(0, props.filename!.length - 5) + ".json",
                    JSON.stringify(props.contents, null, 4),
                )
            }}>
            <GetAppIcon />
        </IconButton>
    )
}

function mapStateToProps(state: storeState): downloadProps {
    return {
        contents: state.file_contents,
        filename: state.file_path,
    };
}

export default connect(mapStateToProps)(Download);
