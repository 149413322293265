import React from 'react';
import { connect } from "react-redux";
import { create_path_action, drop_error } from "./action-creaters"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    dropzone: {
        height: '100vh'
    },
}));


interface dzProps { 
    on_drop: { (path: string, file: File): void } 
    on_error: { (message: string): void } 
}
const _DropZone: React.FC<dzProps> = (props) => {

    const classes = useStyles();
    const onDrop = (ev: React.DragEvent<HTMLDivElement>) => {
        ev.stopPropagation();
        ev.preventDefault();

        ev.persist()

        var file: File
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            let fileItem: DataTransferItem
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    if (typeof fileItem! !== 'undefined') {
                        props.on_error("please drop a single file")
                        return;

                    }
                    fileItem = ev.dataTransfer.items[i]
                }
            }
            if (typeof fileItem! === 'undefined') {
                props.on_error("no file recieved")
                return;
            }
            file = fileItem.getAsFile()!;
        } else {
            // Use DataTransfer interface to access the file(s)
            if (ev.dataTransfer.files.length > 1) {
                props.on_error("please drop a single file")
                return;
            }
            if (ev.dataTransfer.files.length === 0) {
                props.on_error("no file recieved")
                return;
            }
            file = ev.dataTransfer.files[0]
        }


        // var reader = new FileReader();
        // reader.onload = function (event) {
        props.on_drop(file!.name, file)
        // };
        // reader.readAsArrayBuffer(file!);
        // reader.readAsText(file);

    }
    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    }

    return (

        <div id="drop_zone" onDrop={onDrop} onDragOver={onDragOver} className={classes.dropzone} >
            {props.children}
        </div>
    )
}

function mapDispatchToProps(dispatch: { (x: any): void }) {
    return {
        on_drop: (path: string, file: File) => {
            dispatch(create_path_action(path, file))
        },
        on_error: (message: string) => {
            dispatch(drop_error(message))
        }
    }
}

const DropZone = connect(null, mapDispatchToProps)(_DropZone);
export default DropZone
