import thunkMiddleware, { ThunkAction } from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { default_options } from "./options";

export type action =
    | set_contents_action
    | set_path_action
    | invalid_file
    | set_jmespath
    | deactivate_jmespath
    | activate_jmespath
    | jmespath_error
    | toggle_option_action;

export interface storeState {
    file_path?: string;
    file?: File;
    is_invalid?: boolean;
    file_contents?: any;
    error_message?: string;
    options: { [option: string]: boolean };
    loading: boolean;
    jmesquery: string;
    jmesquery_is_valid: boolean;
    jmesquery_result?: any;
    jmesquery_error?: any;
}

export interface set_path_action {
    type: "SET_PATH";
    file: File;
    path: string;
}

export interface invalid_file {
    type: "INVALID_FILE";
    path: string;
    message: string;
}

export interface set_contents_action {
    type: "SET_CONTENTS";
    path: string;
    payload: any;
}

export interface toggle_option_action {
    type: "TOGGLE_OPTION";
    name: string;
}

export interface set_jmespath {
    type: "SET_JMESPATH";
    payload: string;
    is_valid: boolean;
}

// export interface set_jmespath_result {
//     type: "SET_JMESPATH_RESULT";
//     payload: any;
// }

export interface jmespath_error {
    type: "JMESPATH_ERROR";
    message: string;
}

export interface deactivate_jmespath {
    type: "DEACTIVATE_JSMESPATH";
}

export interface activate_jmespath {
    type: "ACTIVATE_JMESPATH";
    payload: any;
}

function reducer(
    state: storeState = {
        options: default_options,
        loading: false,
        jmesquery: "",
        jmesquery_is_valid: false
    },
    action: action
): storeState {
    switch (action.type) {
        case "SET_PATH":
            return {
                ...state,
                file_path: action.path,
                file: action.file,
                is_invalid: false,
                file_contents: null,
                error_message: undefined,
                loading: true,
                jmesquery_result: undefined
            };

        case "INVALID_FILE":
            return {
                ...state,
                file_path: action.path,
                is_invalid: true,
                error_message: action.message,
                loading: false,
                jmesquery_result: undefined
            };
        case "SET_CONTENTS":
            return {
                ...state,
                file_path: action.path,
                is_invalid: false,
                error_message: undefined,
                file_contents: action.payload,
                loading: false,
                jmesquery_result: undefined
            };
        case "TOGGLE_OPTION": {
            let out: storeState = {
                ...state,
                error_message: undefined,
                options: { ...state.options },
                jmesquery_result: undefined
            };
            out.options[action.name] = !out.options[action.name];
            return out;
        }

        case "SET_JMESPATH":
            return {
                ...state,
                jmesquery: action.payload,
                jmesquery_is_valid: action.is_valid
            };
        case "ACTIVATE_JMESPATH":
            return {
                ...state,
                jmesquery_result: action.payload,
                jmesquery_error: undefined
            };
        case "DEACTIVATE_JSMESPATH":
            return {
                ...state,
                jmesquery_result: undefined
            };
        case "JMESPATH_ERROR":
            return {
                ...state,
                jmesquery_is_valid: false,
                jmesquery_error: action.message
            };

        default:
            //alert("unhandled action type: " + (action as any).type);
            return state;
    }
}

export type ThunkResult<R> = ThunkAction<R, storeState, undefined, action>;

const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    reducer,
    {
        options: default_options,
        loading: false,
        jmesquery: "",
        jmesquery_is_valid: false
    },
    composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;
